import React from 'react'
import mission from '../assets/mission.png'
import jobseeker from '../assets/jobseeker.png'
import employer from '../assets/employer.png'
import FadeIn from 'react-fade-in';



function About() {
  return (
    <FadeIn delay={100} transitionDuration={1000} className='scroll-smooth'>
      <section className="text-gray-600 body-font">
        {/* Mission */}
        <div className="container mx-auto flex px-5 pt-24 pb-12 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img className="object-cover object-center rounded" alt="hero" src={mission} />
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 underline underline-offset-4 decoration-indigo-500">Our Mission</h1>

            <p className="mb-8 text-lg font-semibold leading-relaxed">Actively connecting Youth in Canada to their next opportunity.</p>

            <p> At YouthJobLeads we work with verified employers to source opportunities for youth in Canada.
              Research shows youth are often overlooked for work and that adds to the already stressful task of finding work.
              Our goal is simple, we want to work with verified employers to source jobs targeted for Canadian youth and make it really easy and straightforward for our applicants to find the right work
              and eliminate some of the stress that can come with job searching. </p>
          </div>
        </div>

        {/* For Job Seekers */}
        <div className="container mx-auto flex px-5 py-12 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 underline underline-offset-4 decoration-indigo-500">For Job Seekers</h1>

            <p className="mb-8 text-lg font-semibold leading-relaxed">Quality sourced jobs from verified employers.</p>

            <p className='pr-8'>Our goal is to connect you to your next job in Canada as soon as possible and get you started with your professional
              journey. We focus specifically on jobs/opportunities for Youth in Canada as we want to help everyone
              learn and grow. Job Search can cause a lot of anxiety, uncertainty and more. We go the extra mile to source jobs
              from verified employers so that you have the peace of mind of knowing your application will be received and considered. Start
              your search today and find your right professional fit. Good Luck!
            </p>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img className="object-cover object-center rounded" alt="hero" src={jobseeker} />
          </div>
        </div>

        {/* For Employers */}
        <div className="container mx-auto flex px-5 py-12 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img className="object-cover object-center rounded" alt="hero" src={employer} />
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 underline underline-offset-8 decoration-indigo-500">For Employers</h1>

            <p className="mb-8 text-lg font-semibold leading-relaxed">Actively connecting Canadian youth to their next opportunity.</p>

            <p> Canada's worker shortage issues are causing businesses to curtail operations, reduce hours and in some cases shut down completely.
              We want to help which is why we created YouthJobLeads, to find you talented workers who find it hard to find it work, Youth.
              Post your jobs today and find your next employee of the quarter. We make it really easy for your future prospect employees to apply which ensures
              that you receive lots of quality applications. Start today!</p>
          </div>
        </div>


      </section>
    </FadeIn>
  )
}

export default About