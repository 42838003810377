import React from 'react';
import y1 from '../assets/y1.png';
import y2 from '../assets/y2.png';
import y3 from '../assets/y3.png';

const Resources = () => {
    return (
        <section className="text-gray-600 body-font">
            <div className="flex flex-wrap w-full mt-20 flex-col items-center text-center">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
                    Resources
                </h1>
                <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">
                    Need help? check out the resources below!
                </p>
            </div>
            <div className="container px-5 py-24 mx-auto">
                <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
                    <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
                        <div className="rounded-lg h-64 overflow-hidden">
                            <img
                                alt="content"
                                className="object-cover object-center h-full w-full"
                                src={y1}
                            />
                        </div>
                        <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
                            Canada Summer Jobs.
                        </h2>
                        <p className="text-base leading-relaxed mt-2">
                            Canada Summer Jobs (CSJ) is part of the Youth Employment and Skills Strategy, which is the Government of Canada’s commitment to help young people—particularly those facing barriers to employment—get the information and gain the skills, work experience and abilities they need to transition successfully into the labour market.
                        </p>
                        <a
                            rel="noreferrer"
                            href="https://www.canada.ca/en/employment-social-development/news/2020/12/csj.html"
                            className="text-indigo-500 inline-flex items-center mt-3"
                        >
                            Learn More
                            <svg
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                className="w-4 h-4 ml-2"
                                viewBox="0 0 24 24"
                            >
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </a>
                    </div>
                    <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
                        <div className="rounded-lg h-64 overflow-hidden">
                            <img
                                alt="content"
                                className="object-cover object-center h-full w-full"
                                src={y2}
                            />
                        </div>
                        <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
                            Student employment services and information.
                        </h2>
                        <p className="text-base leading-relaxed mt-2">
                            Job opportunities and Government of Canada hiring programs for students.
                        </p>
                        <a
                            rel="noreferrer"
                            href="https://www.canada.ca/en/services/jobs/opportunities/student.html"
                            className="cursor-pointer text-indigo-500 inline-flex items-center mt-3"
                        >
                            Learn More
                            <svg
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                className="w-4 h-4 ml-2"
                                viewBox="0 0 24 24"
                            >
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </a>
                    </div>
                    <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
                        <div className="rounded-lg h-64 overflow-hidden">
                            <img
                                alt="content"
                                className="object-cover object-center h-full w-full"
                                src={y3}
                            />
                        </div>
                        <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
                            Student Employment Services.
                        </h2>
                        <p className="text-base leading-relaxed mt-2">
                        Apply to be considered for full or part-time job opportunities across the country through the Federal Student Work Experience Program (FSWEP)
                        </p>
                        <a
                            rel="noreferrer"
                            href="https://www.canada.ca/en/services/jobs/opportunities/student.html"
                            className="cursor-pointer text-indigo-500 inline-flex items-center mt-3"
                        >
                            Learn More
                            <svg
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                className="w-4 h-4 ml-2"
                                viewBox="0 0 24 24"
                            >
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Resources;