import React, { useEffect, useState } from "react";

import { Card, Pagination } from "../components";
import FadeIn from "react-fade-in";
import { jobsPageQuery, partTimeQuery, fullTimeQuery, totalOpenings, totalFtOpenings, totalPtOpenings } from "../utils/Data";
import { client } from '../client'
import { Link } from "react-router-dom";





function JobCardRender(job) {

  return (
    <Card
      key={job._id}
      btnId={job._id}
      title={job.title}
      categories={job.categories}
      city={job.city}
      salary={job.salary}
      postedOn={job.postedOn}
    />
  );
}




function Jobs() {

  const [currentPage, setCurrentPage] = useState(0)
  const [maxOpenings, setMaxOpenings] = useState()
  const [searchTerm, setSearchTerm] = useState("term");
  const [searchLocation, setSearchLocation] = useState("loc");
  const [jobsArr, setJobsArr] = useState([]);
  const [fullTimeFilter, setFullTimeFilter] = useState(false)
  const [partTimeFilter, setPartTimeFilter] = useState(false)

  function checkFilter(){
    let fulltime = document.getElementById('fulltime')
    let parttime = document.getElementById('parttime')

    if(fulltime.checked == true){
      setFullTimeFilter(true)
      setCurrentPage(0)
    }else if (fulltime.checked == false){
      setFullTimeFilter(false)
      setCurrentPage(0)
    }

    if(parttime.checked == true){
      setPartTimeFilter(true)
    }else if (parttime.checked == false){
      setPartTimeFilter(false)
    }

  }

  function activePageinc (){
    if(currentPage < ((maxOpenings/10)-1))
      setCurrentPage(currentPage+1)
  }

  function activePagedec(){

    if(currentPage != 0 ) {
      setCurrentPage(currentPage-1)
    }
    
  }

  useEffect(()=>{
    if((partTimeFilter == false && fullTimeFilter == false) || (partTimeFilter == true && fullTimeFilter == true)){
      //job cards
      client.fetch(jobsPageQuery(currentPage)).then((jobs) => {
        setJobsArr(jobs);
      }).catch((err) => console.log(err));
      //total
      client.fetch(totalOpenings)
      .then((res)=>setMaxOpenings(res)).catch((err) => console.log(err));

    } else if((partTimeFilter == false && fullTimeFilter == true)){
      //jobs
      client.fetch(fullTimeQuery(currentPage)).then((jobs) => {
        setJobsArr(jobs);
      }).catch((err) => console.log(err));

      //total
      client.fetch(totalFtOpenings)
      .then((res)=>setMaxOpenings(res)).catch((err) => console.log(err));


    }else if((partTimeFilter == true && fullTimeFilter == false)){
      client.fetch(partTimeQuery(currentPage)).then((jobs) => {
        setJobsArr(jobs);
      }).catch((err) => console.log(err));

      client.fetch(totalPtOpenings)
      .then((res)=>setMaxOpenings(res)).catch((err) => console.log(err));
    }
    
    
  },[fullTimeFilter, partTimeFilter,currentPage])
  
   
  

  return (
    <FadeIn delay={100} transitionDuration={1000} className="scroll-smooth">
      <section className="md:px-8 md:mx-8 py-4 ">
        
        {/* Search Component */}
        <div className="flex md:flex-row flex-col w-full justify-between items-center md:items-end  md:px-8 md:mx-8  ">
        <div className="relative flex md:flex-row flex-col md:mr-4 w-4/5 mx-auto md:w-full lg:w-full items-center mt-4 ">
          <input
          onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Job title, keywords, or company"
            type="text"
            id="hero-field"
            name="hero-field"
            className="w-full bg-gray-100 rounded border bg-opacity-50 border-gray-200 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          />
          <input
          onChange={(e) => setSearchLocation(e.target.value)}
            placeholder="City, or Province"
            type="text"
            id="hero-field"
            name="hero-field"
            className="w-full md:ml-4 md:mt-0 mt-4 bg-gray-100 rounded border bg-opacity-50 border-gray-200 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          />
        </div>
        <Link to={`/search/${searchLocation}/${searchTerm}`}>
        <button className="inline-flex mt-4 md:mt-0 text-white font-semibold font-[Poppins] bg-indigo-500 px-6 py-1 rounded-lg border-2 border-indigo-500 hover:text-indigo-500 hover:bg-white ease-in duration-150 text-lg">
          Search
        </button>
        </Link>
      </div>
      <div className=" flex items-center mb-24 mt-4 md:px-8 md:mx-8 px-2 mx-2 justify-center">
        <p className="mr-4 font-semibold text-gray-900">Filter:</p>
        <input
          onClick={checkFilter}
          className="mr-2 text-indigo-500  "
          type="checkbox"
          id="fulltime"
          name="Full-Time"
         
        />
        <label className="mr-4" htmlFor="full-time">
          Full Time
        </label>

        <input
          onClick={checkFilter}
          className="mr-2  text-indigo-500 "
          type="checkbox"
          id="parttime"
          name="Full-Time"
        />
        <label htmlFor="part-time">Part Time</label>
      </div>
        {/* Section below will need to change based on routes */}
        <div className="md:px-16 md:mx-16 px-2 mx-2 mb-8 ">
         
          {jobsArr.map(JobCardRender)}
        </div>

        {/* Pagination */}
        <Pagination 
          currentPage={currentPage}
          increment={activePageinc}
          decrement={activePagedec}
          maxOpenings={maxOpenings}

        />
      </section>
    </FadeIn>
  );
  
}

export default Jobs;
