import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../client";
import { homePageJobsQuery } from "../utils/Data";
import Card from "./Card";

function JobCardRender(job) {
  return (
    <Card
      key={job._id}
      btnId={job._id}
      title={job.title}
      categories={job.categories}
      city={job.city}
      salary={job.salary}
      postedOn={job.postedOn}
    />
  );
}

function Jobs() {
  const [jobsArr, setJobsArr] = useState([]);

  useEffect (() => {
    
      client
        .fetch(homePageJobsQuery)
        .then((jobs) => {
          setJobsArr(jobs);
        })
        .catch((err) => console.log(err));
      
    
  },[])
  

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
            Latest Postings
          </h1>
          <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">
            Search and discover your next job in Canada right here!
          </p>
        </div>

        
        {jobsArr.map(JobCardRender)}

        <Link to="/jobs">
          <button className="flex mx-auto mt-16 text-white font-[Poppins] bg-indigo-500 px-6 py-1 rounded-lg border-2 border-indigo-500 hover:text-indigo-500 hover:bg-white ease-in duration-150 text-lg">
            See All
          </button>
        </Link>
      </div>
    </section>
  );
}

export default Jobs;
